import {Action, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import {LoginByToken, Logout, ToggleSidePanel, Search} from './user.actions';
import {Injectable} from '@angular/core';
import {map, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {AuthContract} from "../../shared/contracts/auth.contract";
import {AuthToken} from "../../shared/models/auth-token.model";
import {StudioUserRoles, User} from "../../shared/models/user.model";

export interface UserStore {
  toggleSidePanel: boolean;
  search: string;
  authUser: /** using three flags  */
  /**  auth user */
    User
    /** no auth user */
    | null
    /** request User in progress, its the mark for guards  */
    | undefined;
  authToken: AuthToken | undefined;
}

const getAuthUserFromStorage = (): AuthToken => {
  const authTokenString = localStorage.getItem('authToken');
  const authToken = JSON.parse(authTokenString || 'null');

  return authToken;
};

const getNoAuthSate = (): UserStore => ({
  toggleSidePanel: false,
  search: '',
  authUser: null,
  authToken: getAuthUserFromStorage()
});

@State<UserStore>({
  name: 'user',
  defaults: { ...getNoAuthSate() },
})
@Injectable()
export class UserState implements NgxsOnInit {
  constructor(
    private authContract: AuthContract
  ) {}

  @Selector()
  public static toggleSidePanel(state: UserStore): boolean {
    return state.toggleSidePanel;
  }

  @Selector()
  public static search(state: UserStore): string {
    return state.search;
  }

  @Selector()
  public static authUser(state: UserStore) {
    return state.authUser;
  }

  @Selector()
  public static authToken(state: UserStore): AuthToken | undefined {
    return state.authToken;
  }

  @Selector()
  public static getPrimaryRoleName(state: UserStore) {
    return 'NONE';
  }

  @Action(ToggleSidePanel)
  private toggleSidePanel({ patchState }: StateContext<UserStore>, { toggleSidePanel }: ToggleSidePanel) {
    patchState({ toggleSidePanel });
  }

  @Action(Search)
  private search({ patchState }: StateContext<UserStore>, { search }: Search) {
    patchState({ search });
  }

  @Action(LoginByToken)
  private loginByToken({ setState, getState }: StateContext<UserStore>, { token }: LoginByToken) {
    return this.loginByAuthToken(token).pipe(tap((state) => setState(state)));
  }

  @Action(Logout)
  private logout({ setState }: StateContext<UserStore>) {
    localStorage.removeItem('authToken');
    setState(getNoAuthSate());
  }

  @Selector()
  public static isEventAdmin(state: UserStore): boolean {
    return false;
  }

  @Selector()
  public static isStudioAdmin(state: UserStore): boolean {
    return this.hasStudioRole(StudioUserRoles.ADMIN, state);
  }

  @Selector()
  public static isStudioOwner(state: UserStore): boolean {
    return this.hasStudioRole(StudioUserRoles.STUDIO_OWNER, state);
  }

  @Selector()
  public static isStudioStaff(state: UserStore): boolean {
    return this.hasStudioRole(StudioUserRoles.STAFF, state);
  }

  @Selector([UserState.isStudioAdmin, UserState.isStudioOwner, UserState.isStudioStaff, UserState.isEventAdmin])
  public static landingUrl(state: UserStore, isAdmin: boolean, isStudioOwner: boolean, isStaff: boolean, isEventAdmin: boolean): string {
    if (!state.authUser) {
      return '/';
    }

    return '/';
  }

  private static hasStudioRole(role: StudioUserRoles, state: UserStore): boolean {
    if (state.authUser && state.authUser.studioAccount && state.authUser.studioAccount.roles) {
      for (let i = 0; i < state.authUser.studioAccount.roles.length; i++) {
        if (role == state.authUser.studioAccount.roles[i].name) {
          return true;
        }
      }
    }
    return false;
  }

  private loginByAuthToken(token: string): Observable<UserStore> {
    return this.authContract.getAuthByToken(token).pipe(map(authUser => {
      if (!authUser) {
        localStorage.removeItem('authToken');
        return getNoAuthSate();
      }

      const toggleSidePanel = false;

      const authToken: AuthToken = {token, userId: 0};
      localStorage.setItem('authToken', JSON.stringify(authToken));
      localStorage.setItem('token', token);

      const search = '';

      return {authUser, authToken, toggleSidePanel, search};
    }));
  }

  public async ngxsOnInit({ getState, dispatch, setState }: StateContext<UserStore>): Promise<void> {
    const authToken = getState().authToken;
    if (authToken) {
      dispatch(new LoginByToken(authToken.token));
    }
  }

}
