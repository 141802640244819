import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PublicStoreStudio } from '../models/studio.model';
import { StoreStock } from '../models/store-stock.model';
import { ProductDetail, StoreProduct } from '../models/store-product.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from "../../../environments/environment";
import {PaymentProviders} from "../constants/payment-providers.enum";

@Injectable({
  providedIn: 'root'
})
export class StoreContract {
  constructor(private http: HttpClient) {}

  public getStudiosWithPublicStore() {
    return this.http
      .get<PublicStoreStudio[]>(`${environment.studioApi}api/v1/public/studio/public_store`)
      .pipe(map((studios) => studios.filter((studio) => studio.cardPaymentProvider === PaymentProviders.stackPay)));
  }

  public getProducts(params: any): Observable<ProductDetail[]> {
    return this.http.get<ProductDetail[]>(`${environment.studioApi}api/v1/public/store/filter-new`,{params});
  }

  public getStoreProduct(studioId: number, productId: number) {
    return this.http.get<StoreProduct>(`${environment.studioApi}api/v1/public/store/${studioId}/${productId}`);
  }

  public getProductStocks(studioId: number, productId: number) {
    return this.http
      .get<StoreStock[]>(`${environment.studioApi}api/v1/public/store/${studioId}/stock/filter`, {
        params: { productId },
      })
      .pipe(
        map((storeStocks) => {
          const storeStock = storeStocks[0];

          if (!storeStock || storeStock.product.id !== +productId) {
            return null;
          }

          return storeStock.items;
        }),
      );
  }

  public getStoreBanner(studioId: number) {
    return this.http.get<any>(`${environment.studioApi}api/v1/public/store/${studioId}/config`);
  }
}
